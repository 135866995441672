import React from "react";
import { useNavigate } from "react-router-dom";

const TermandCondition = () => {
    const navigate = useNavigate();

    const handleMessage = () => {
        navigate('/message');
    };

    const handleLandingPage = () => {
        navigate('/');
    };

    const handleCRegister = () => {
        navigate('/cregister');
    };

    const handleSrpRegister = () => {
        navigate('/srpregister');
    };

    const handleIndRegister = () => {
        navigate('/indregister');
    };

    const handleInvestorRegister = () => {
        navigate('/investregister');
    };

    return (
        <>
            {/* Header Section */}
            <div className="header">
                <button className="btnicon" onClick={handleLandingPage}>
                    <div className="logo2">
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="width1">
                <div className="maindiv">
                    {/* Search Bar */}
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    {/* Registration Buttons */}
                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndRegister}>
                                <img src="images/personalInfo.png" height="25" width="25" alt="Personal Info" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" height="25" width="25" alt="Company Registration" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorRegister}>
                                <img src="images/InvestorsIcon.png" height="25" width="25" alt="Investors" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleSrpRegister}>
                                <img src="images/SalesPartnerIcon.png" height="25" width="25" alt="Sales Partner" />
                            </button>
                        </div>

                        {/* Terms and Conditions */}
                        <div className="loginrightside">
                            <div className="loginformtitle">Company Registration</div>
                            <div className="editinfo">
                                <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                            </div>
                            <div className="formdiv">
                                <div className="companytermheading">Terms & Conditions Agreement*</div>
                                <div className="companytext">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text ever since the 1500s...</p>
                                    <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker.</p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged...</p>
                                </div>

                                {/* Checkbox for Agreement */}
                                <div className="termagree">
                                    <label>
                                        <input type="checkbox" /> I Agree with Terms & Conditions*
                                    </label>
                                </div>

                                {/* Submit Button */}
                                <div>
                                    <button onClick={handleMessage} className="sub1">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Social Media Icons */}
                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="Tiktok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermandCondition;
