import React from 'react';
import '../styles/ControlPanel.css'; // External CSS for styling
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const ControlPanel = () => {
    const navigate = useNavigate();

    const handleMainpage = () => {
        navigate('/');
    };
    const handlevehicacompatment=()=>{
        navigate('/vehcamp')
    }
    const handlemploye = () =>{
        navigate('/employeinfo')
    }
    const handfrenchies = () =>{
        navigate('/franchies')
    }
    return (
        <>
            {/* Header with logo, search bar, and icons */}
            <div className="header">
                <button className="btnicon"> <div className="logo2" onClick={handleMainpage}>
                    <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>
            

            {/* Main Control Panel Section */}

            <div className="width1">
            
                <div className='maindiv'>
                
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input
                                type="text"
                                placeholder="Search here Business Windows"
                                className="searchfield"
                            />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>
                   
                    <div className='minicontrolglassdiv2'>
                    <div className="left-sidebar">
                    <img src="images/Tiktok.png" alt="Tiktok" />
                <img src="images/FB.png" alt="Facebook" />
                <img src="images/Youtube.png" alt="YouTube" />
                
            </div>
                   
                        <div className="panel ">
                            <h3 >Control Panel</h3>
                            <div className="grid">
                                <div  onClick={handlevehicacompatment} className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Vehical</p>
                                </div>
                                <div className="grid-item1">
                                    <img onClick={handlemploye} src="images/Setting.png" alt="Setting" />
                                    <p>Employee</p>
                                </div>
                                <div className="grid-item1">
                                    <div>
                                    <img onClick={handfrenchies} src="images/Setting.png" alt="Setting" />
                                    </div>
                                    <p>Franchise</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>

                            </div>
                        </div>
                        

                        {/* Add more panels */}
                        <div className="panel">
                            <h3>Control Panel</h3>
                            <div className="grid">
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>


                            </div>
                        </div>
                        <div className="panel">
                            <h3>Control Panel</h3>
                            <div className="grid">
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>


                            </div>
                        </div>
                        <div className="panel">
                            <h3>Control Panel</h3>
                            <div className="grid" >
                                <div className="grid-item1">
                                    <img src="images/personalInfo.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div><div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>
                                <div className="grid-item1">
                                    <img src="images/Setting.png" alt="Setting" />
                                    <p>Setting</p>
                                </div>


                            </div>
                            <div className="right-sidebar">
                <img src="images/Tiktok.png" alt="Tiktok" />
                <img src="images/FB.png" alt="Facebook" />
                <img src="images/Youtube.png" alt="YouTube" />
                {/* Add more icons as needed */}
            </div>
                        </div>
                        
                        
                    </div>
                    
                    <div className="icondiv3" >
                        <button>
                            <img src="images/Support.png" alt="Support" />
                        </button>
                        <button>
                            <img src="images/Helpline.png" alt="Helpline" />
                        </button>
                        <button>
                            <img src="images/Complaint.png" alt="Complaint" />
                        </button>
                        <button>
                            <img src="images/Update.png" alt="Update" />
                        </button>
                        <button>
                            <img src="images/Tiktok.png" alt="Tiktok" />
                        </button>
                        <button>
                            <img src="images/FB.png" alt="Facebook" />
                        </button>
                        <button>
                            <img src="images/YOutube.png" alt="YouTube" />
                        </button>
                        <button>
                            <img src="images/Whatsapp.png" alt="Whatsapp" />
                        </button>
                        <button>
                            <img src="images/Twitter.png" alt="Twitter" />
                        </button>
                        <button>
                            <img src="images/Insta.png" alt="Instagram" />
                        </button>
                        <button>
                            <img src="images/Linkedin.png" alt="LinkedIn" />
                        </button>
                        <button>
                            <img src="images/Pinterest.png" alt="Pinterest" />
                        </button>
                        <button>
                            <img src="images/Google Plus.png" alt="Google Plus" />
                        </button>
                        <button>
                            <img src="images/Reddit.png" alt="Reddit" />
                        </button>
                    </div>
                    
                    {/* Repeat for other panels */}
                </div>
                


            </div>
        </>
    );
};

export default ControlPanel;
