import React from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.css';

const Main = () => {
    const navigate = useNavigate();

    const handleSignIn = () => {
        navigate('/signin');
    };

    return (
        <>
            <div className="header">
                <button className="btnbusiness">
                    <div className="logo">
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting">
                        <img src="images/Setting.png" alt="Setting" />
                    </button>
                    <button className="phone">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv">
                        <div className="glassinnerdiv">
                            {/* First row of icons */}
                            <div className="box1">
                                <button className="btnicon" onClick={handleSignIn}>
                                    <div className="boxinner1">
                                        <img src="images/Log.png" alt="Log&Reg" height="28" width="37" />
                                        <p>Log&Reg</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner2">
                                        <img src="images/offer.png" alt="Hot Offers" height="28" width="37" />
                                        <p>Hot Offers</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner3">
                                        <img src="images/Buy sell.png" alt="Buy&Sell" height="28" width="37" />
                                        <p>Buy&Sell</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner4">
                                        <img src="images/Rental.png" alt="Rentals" height="28" width="37" />
                                        <p>Rentals</p>
                                    </div>
                                </button>
                            </div>

                            {/* Second row of icons */}
                            <div className="box2">
                                <button className="btnicon">
                                    <div className="boxinner5">
                                        <img src="images/Services.png" alt="Services" height="28" width="37" />
                                        <p>Services</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner6">
                                        <img src="images/Artboard1.png" alt="Products" height="28" width="37" />
                                        <p>Products</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner7">
                                        <img src="images/QuickIn.png" alt="Quick in" height="28" width="37" />
                                        <p>Quick in</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner8">
                                        <img src="images/Center.png" alt="Centers" height="28" width="37" />
                                        <p>Centers</p>
                                    </div>
                                </button>
                            </div>

                            {/* Third row of icons */}
                            <div className="box1">
                                <button className="btnicon">
                                    <div className="boxinner1">
                                        <img src="images/Media Frame.png" alt="Media Frame" height="28" width="37" />
                                        <p>Media Frame</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner2">
                                        <img src="images/Investors.png" alt="Investors" height="28" width="37" />
                                        <p>Investors</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner3">
                                        <img src="images/Auctions.png" alt="Auctions" height="28" width="37" />
                                        <p>Auctions</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner4">
                                        <img src="images/Community.png" alt="Community" height="28" width="37" />
                                        <p>Community</p>
                                    </div>
                                </button>
                            </div>

                            {/* Fourth row of icons */}
                            <div className="box2">
                                <button className="btnicon">
                                    <div className="boxinner1">
                                        <img src="images/Support.png" alt="Support" height="28" width="37" />
                                        <p>Support</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner2">
                                        <img src="images/Helpline.png" alt="Helpline" height="28" width="37" />
                                        <p>Helpline</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner3">
                                        <img src="images/Complaint.png" alt="Complaint" height="28" width="37" />
                                        <p>Complaint</p>
                                    </div>
                                </button>

                                <button className="btnicon">
                                    <div className="boxinner4">
                                        <img src="images/Update.png" alt="Update" height="28" width="37" />
                                        <p>Update</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="icondiv">
                        <button><img src="images/Tiktok.png" alt="Tiktok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Main;
