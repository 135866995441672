import React from "react";
import { useNavigate } from "react-router-dom";

const EmployeInfo = () => {
    const navigate = useNavigate();
    const handlemploeedudetail=(e)=>{
        navigate('/empexp')
    }
    const handleemployeducation = (e) => {
        navigate ('/empedu')
    }
    const handleemploy = (e) => {
        navigate ('/employeinfo')
    }
    const handleLandingPage = (e) => {
        navigate('/');
    }

    return (
        <>
            <div className="header">
            <button className='btnicon'>
                    <div className="logo2" onClick={handleLandingPage}>
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <div className="infobtn">
                                <button onClick={handleemploy} className="loginleftsidebtn2">
                                    <img src="images/Login Icon .png" height="25" width="25" alt="Login Icon" />
                                </button>
                                Personal Info
                            </div>
                            <div className="infobtn">
                                <button onClick={handleemployeducation} className="loginleftsidebtn2">
                                    <img src="images/EducationIcon.png" height="25" width="25" alt="Education Icon" />
                                </button>
                                Education
                            </div>
                        </div>

                        <div className="loginrightside">
                            <div className="loginformtitle">Employee Information</div>
                            <div className="editinfo2">
                                <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                            </div>
                            <div className="personimage">
                                <img src="images/image1.jpg" alt="Person" />
                            </div>
                            <div className="formdiv">
                                <input type="text" className="inputbg6" placeholder="School/College" required />
                                <select className="select1" style={{ marginRight: '5px' }}>
                                    <option>Subject Category</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                </select>
                                <input type="date" className="inputbg4" placeholder="Year of Completion" required />
                                <input type="text" className="inputbg5" placeholder="Grade" required />
                                <input type="text" className="inputbg4" placeholder="University" required />
                                <input type="date" className="inputbg4" placeholder="Year of Completion*" required />
                                <select className="select1">
                                    <option>Graduation Subjects</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                </select>
                                <div style={{ width: '100%', float: 'left' }}>
                                    <input type="text" className="inputbg4" placeholder="Extra Qualification" required />
                                    <select className="select1" style={{ marginRight: '5px' }}>
                                        <option>Graduation Subjects</option>
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                    </select>
                                    <input type="date" className="inputbg5" placeholder="Year of Completion" required />
                                </div>
                                <input type="text" className="inputbg6" placeholder="Extra Qualification Details" required />
                                <input type="text" className="inputbg6" placeholder="Any Extra Training" required />
                                <input type="text" className="inputbg6" placeholder="Any Tehnical Training" required />
                                <input type="text" className="inputbg4" placeholder="Blood Group" required />
                                <input type="text" className="inputbg9" placeholder="Any Health Isuue" required />
                                
                                <div className="clicknext">
                                    <button onClick={handlemploeedudetail}>Click Next to Proceed* <img src="images/ClickNextToProced.png" alt="Click Next" /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="TikTok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeInfo;
