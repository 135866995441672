import React from "react";
import { useNavigate } from "react-router-dom";
const Franchiessubmit = () => {
  const navigate = useNavigate();
  
const handleFrenchies = (e) =>{
    navigate ('/franchies')
}
const handlelandingpage = (e) => {
    navigate('/')
}
const handlefranchbussinesinfo = (e) =>{
    navigate('/frenchbussinesinfo')
}

const handlefranchinfo= (e) =>{
  navigate('/franchiesinfo')
}
  return (
    <>
      <div className="header">
      <button className='btnicon'>
                    <div className="logo2" onClick={handlelandingpage}>
                        <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                    </div>
                </button>
        <div className="topicons">
          <button className="setting2">
            <img src="images/Setting.png" alt="Settings" />
          </button>
          <button className="phone2">
            <img src="images/Phone.png" alt="Phone" />
          </button>
        </div>
      </div>

      <div className="width1">
        <div className="maindiv">
          <div className="searchbar2">
            <form>
              <input type="submit" className="submitbtn" />
              <input
                type="text"
                placeholder="Search here Business Windows"
                className="searchfield"
              />
              <input type="button" className="voicebtn" />
            </form>
          </div>

          <div className="glassdiv2">
            <div className="loginleftside">
              <div className="loginleftsidetext">Select Field</div>
              <div className="infobtn">
                <button onClick={handleFrenchies} className="loginleftsidebtn2">
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Personal Info Icon"
                  />
                </button>
                Personal Info
              </div>
              <div onClick={handlefranchbussinesinfo} className="infobtn">
                <button className="loginleftsidebtn2">
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Business Info Icon"
                  />
                </button>
                Business Info
              </div>
              <div onClick={handlefranchinfo} className="infobtn">
                <button className="loginleftsidebtn2">
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Franchise Info Icon"
                  />
                </button>
                Franchise Info
              </div>
            </div>

            <div className="loginrightside">
              <div className="loginformtitle">Franchise Registration</div>
              <div className="editinfo">
                <button>
                  Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" />
                </button>
              </div>
              <div className="formdiv">
                <div className="companytermheading">Terms & Conditions Agreement*</div>
                <div className="companytext">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </p>
                  <p>
                    It has survived not only five centuries, but also the leap into electronic 
                    typesetting, remaining essentially unchanged.
                  </p>
                  <p>
                    It was popularised in the 1960s with the release of Letraset sheets containing 
                    Lorem Ipsum passages, and more recently with desktop publishing software like 
                    Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>

                <div className="termagree">
                  <input type="checkbox" /> I Agree with Terms & Conditions*
                </div>
                <div>
                  <button className="sub1">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <div className="icondiv2">
            <button>
              <img src="images/Support.png" alt="Support" />
            </button>
            <button>
              <img src="images/Helpline.png" alt="Helpline" />
            </button>
            <button>
              <img src="images/Complaint.png" alt="Complaint" />
            </button>
            <button>
              <img src="images/Update.png" alt="Update" />
            </button>
            <button>
              <img src="images/Tiktok.png" alt="TikTok" />
            </button>
            <button>
              <img src="images/FB.png" alt="Facebook" />
            </button>
            <button>
              <img src="images/YOutube.png" alt="YouTube" />
            </button>
            <button>
              <img src="images/Whatsapp.png" alt="WhatsApp" />
            </button>
            <button>
              <img src="images/Twitter.png" alt="Twitter" />
            </button>
            <button>
              <img src="images/Insta.png" alt="Instagram" />
            </button>
            <button>
              <img src="images/Linkedin.png" alt="LinkedIn" />
            </button>
            <button>
              <img src="images/Pinterest.png" alt="Pinterest" />
            </button>
            <button>
              <img src="images/Google Plus.png" alt="Google Plus" />
            </button>
            <button>
              <img src="images/Reddit.png" alt="Reddit" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Franchiessubmit;
