import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.css'
import { useNavigate } from "react-router-dom";



const Reset = () => {

    const navigate = useNavigate()

    const handleSignIn = (e) => {
        navigate('/signin')
    }
    const handleMainpage = (e) => {
        navigate('/')
    }

    return (
        <>
            <div className="header">
                <button className="btnicon"> <div className="logo" onClick={handleMainpage}><img src="images/BusinessWindow.png" alt="Bussines Img" /></div></button>
                <div className="topicons">
                    <button className="setting"><img src="images/Setting.png" alt="Setting" /></button>
                    <button className="phone"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>


            <div className="width1">
                <div className="maindiv">



                    <div className="searchbar">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>


                    <div className="logindiv">
                        <div className="resticon"><img src="images/PasswordRestoredIcon.png" alt="img password" /></div>
                        <div className="resttext2">Your Password Has been Reset<br />
                            Click on The Link Below to Login Again</div>
                        <button className="loginagian btnicon"> <div className="loginagian" onClick={handleSignIn}><img src="images/Login Icon .png" alt="login imag" height="18" width="18" /> Click to Login </div></button>

                    </div>


                    <div className="helpdiv">
                        <div className="helpicons">
                            <img src="images/Support.png" alt="Support Icon" height="18" width="18" />
                            <p>Support</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Helpline.png" alt="Helpline Icon" height="18" width="18" />
                            <p>Help Line</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Complaint.png" alt="Complaint Icon" height="18" width="18" />
                            <p>Complaint</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Update.png" alt="Update Icon" height="18" width="18" />
                            <p>Update</p>
                        </div>
                    </div>

                    <div className="icondiv" style={{ marginTop: '20px' }}>
                        <button><img src="images/Tiktok.png" alt="TikTok Icon" /></button>
                        <button><img src="images/FB.png" alt="Facebook Icon" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube Icon" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp Icon" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter Icon" /></button>
                        <button><img src="images/Insta.png" alt="Instagram Icon" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn Icon" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest Icon" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus Icon" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit Icon" /></button>
                    </div>





                </div>
            </div>

        </>
    )
}

export default Reset