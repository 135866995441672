import React from 'react';
import '../styles/CompanyRegister.css';
import '../styles/styles.css';
import { useNavigate } from 'react-router-dom';

const SPRegister = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className="header">
                <button className='btnicon'>
                    <div className="logo2" onClick={() => handleNavigation('/')}>
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={() => handleNavigation('/indregister')}>
                                <img src="images/personalInfo.png" height="25" width="25" alt="Personal Info" />
                            </button>
                            <button className="loginleftsidebtn" onClick={() => handleNavigation('/cregister')}>
                                <img src="images/CompanyRegistrationIcon.png" height="25" width="25" alt="Company Registration" />
                            </button>
                            <button className="loginleftsidebtn" onClick={() => handleNavigation('/investregister')}>
                                <img src="images/InvestorsIcon.png" height="25" width="25" alt="Investors" />
                            </button>
                            <button className="loginleftsidebtn" onClick={() => handleNavigation('/srpregister')}>
                                <img src="images/SalesPartnerIcon.png" height="25" width="25" alt="Sales Partner" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" height="25" width="25" alt="Login Icon" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" height="25" width="25" alt="Login Icon" />
                            </button>
                        </div>

                        <div className="loginrightside">
                            <div className="loginformtitle">Sales Partner Registration</div>
                            <div className="editinfo">
                                <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                            </div>
                            <div className="formdiv">
                                <input type="text" className="sprinputbg4" placeholder="First Name" required />
                                <input type="text" className="sprinputbg4" placeholder="Middle Name" required />
                                <input type="text" className="sprinputbg5" placeholder="Last Name" required />
                                <input type="text" className="sprinputbg4" placeholder="Cell No" required />
                                <input type="text" className="sprinputbg4" placeholder="Passport/ID No" required />
                                <input type="text" className="sprinputbg5" placeholder="Nationality" required />
                                <input type="text" className="sprinputbg4" placeholder="Gender" required />
                                <input type="text" className="sprinputbg4" placeholder="Profession" required />
                                <div>
                                    <select className='sprinputbg5'>
                                        <option>Registration Purpose</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <input type="text" className="sprinputbg6" placeholder="Company Name" required />
                                <div>
                                    <select className='sprinputbg7'>
                                        <option>Business Type</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <div>
                                    <select className='sprinputbg7'>
                                        <option>Sales Category</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <input type="text" className="sprinputbg7" placeholder="Office Address" required />
                                <input type="text" className="sprinputbg8" placeholder="Floor" required />
                                <input type="text" className="sprinputbg8" placeholder="Flat" required />
                                <input type="text" className="sprinputbg8" placeholder="Street" required />
                                <input type="text" className="sprinputbg7" placeholder="Email Address" required />
                                <div>
                                    <select className='sprinputbg8'>
                                        <option>County of Residence</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <div>
                                    <select className='sprinputbg8'>
                                        <option>State</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <input type="text" className="sprinputbg8" placeholder="Postal Code" required />
                                <div>
                                    <select className='sprinputbg7'>
                                        <option>Experience Category</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <div>
                                    <select className='sprinputbg7'>
                                        <option>Sales Partner in Any Other Firm</option>
                                        <option>Type 1</option>
                                        <option>Type 2</option>
                                    </select>
                                </div>
                                <div className="clicknext">
                                    <button onClick={() => handleNavigation('/term')}>
                                        Click Next to Proceed* <img src="images/ClickNextToProced.png" alt="Click Next" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="TikTok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SPRegister;
