import React from "react";
import { useNavigate } from "react-router-dom";
const FranchiseInfo = () => {
    const navigate = useNavigate();
    const handlefranchiessubmit= (e) =>{
        navigate('/frachiessubmit')
    }
    const handleFrenchies = (e) =>{
        navigate ('/franchies')
    }
    const handlelandingpage = (e) => {
        navigate('/')
    }
    const handlefranchbussinesinfo = (e) =>{
        navigate('/frenchbussinesinfo')
    }

  return (
    <>
      <div className="header">
      <button className="btnicon"> <div className="logo2" onClick={handlelandingpage}>
                        <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                    </div>
                    </button>
        <div className="topicons">
          <button className="setting2">
            <img src="images/Setting.png" alt="Settings" />
          </button>
          <button className="phone2">
            <img src="images/Phone.png" alt="Phone" />
          </button>
        </div>
      </div>

      <div className="width1">
        <div className="maindiv">
          <div className="searchbar2">
            <form>
              <input type="submit" className="submitbtn" />
              <input
                type="text"
                placeholder="Search here Business Windows"
                className="searchfield"
              />
              <input type="button" className="voicebtn" />
            </form>
          </div>

          <div className="glassdiv2">
            <div className="loginleftside">
              <div className="loginleftsidetext">Select Field</div>
              <div className="infobtn">
                <button className="loginleftsidebtn2" onClick={handleFrenchies}>
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Login Icon"
                  />
                </button>
                Personal Info
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn2" onClick={handlefranchbussinesinfo}>
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Login Icon"
                  />
                </button>
                Business Info
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn2">
                  <img
                    src="images/Login Icon .png"
                    height="25"
                    width="25"
                    alt="Login Icon"
                  />
                </button>
                Franchise Info
              </div>
            </div>

            <div className="loginrightside">
              <div className="loginformtitle">Franchise Registration</div>
              <div className="editinfo2">
                <button>
                  Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" />
                </button>
              </div>
              <div className="personimage">
                <img src="images/image1.jpg" alt="Person" />
              </div>
              <div className="formdiv">
                <select className="select1" style={{ marginRight: "5px" }}>
                  <option>Required Franchise Type*</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
                <input
                  type="text"
                  className="inputbg4"
                  placeholder="Franchise Area"
                  required
                />
                <select className="select1">
                  <option>Franchise Services</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>

                <select className="select1" style={{ marginRight: "5px" }}>
                  <option>Franchise Country</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
                <select className="select1" style={{ marginRight: "5px" }}>
                  <option>State</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
                <select className="select1">
                  <option>City</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
                <input
                  type="text"
                  className="inputbg4"
                  placeholder="Franchise Facility"
                  required
                />
                <input
                  type="text"
                  className="inputbg4"
                  placeholder="Facility Covered Area"
                  required
                />
                <select className="select1">
                  <option>Facility Type</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>

                <div className="files1">
                  <div className="fileleft">
                    <input type="file" className="file1" required />
                    Click Here for Trade Copy
                  </div>
                  <div className="fileleft2">
                    <input type="file" className="file2" required />
                    Click Here for Passport Copy
                  </div>
                  <div className="fileleft2">
                    <input type="file" className="file3" required />
                    Click Here for Request Letter
                  </div>
                </div>

                <div className="clicknext">
                  <button onClick={handlefranchiessubmit}>
                    Click Next to Proceed*{" "}
                    <img
                      src="images/ClickNextToProced.png"
                      alt="Click Next to Proceed"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="icondiv2">
            <button>
              <img src="images/Support.png" alt="Support" />
            </button>
            <button>
              <img src="images/Helpline.png" alt="Helpline" />
            </button>
            <button>
              <img src="images/Complaint.png" alt="Complaint" />
            </button>
            <button>
              <img src="images/Update.png" alt="Update" />
            </button>
            <button>
              <img src="images/Tiktok.png" alt="Tiktok" />
            </button>
            <button>
              <img src="images/FB.png" alt="Facebook" />
            </button>
            <button>
              <img src="images/YOutube.png" alt="YouTube" />
            </button>
            <button>
              <img src="images/Whatsapp.png" alt="Whatsapp" />
            </button>
            <button>
              <img src="images/Twitter.png" alt="Twitter" />
            </button>
            <button>
              <img src="images/Insta.png" alt="Instagram" />
            </button>
            <button>
              <img src="images/Linkedin.png" alt="LinkedIn" />
            </button>
            <button>
              <img src="images/Pinterest.png" alt="Pinterest" />
            </button>
            <button>
              <img src="images/Google Plus.png" alt="Google Plus" />
            </button>
            <button>
              <img src="images/Reddit.png" alt="Reddit" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FranchiseInfo;
