import React from "react";
import { useNavigate } from "react-router-dom";

const CRegister = () => {
    const navigate = useNavigate();

    const handleLandingPage = (e) => {
        navigate('/');
    }

    const handleCRegister = (e) => {
        navigate('/cregister');
    }

    const handleSrpRegister = (e) => {
        navigate('/srpregister');
    }

    const handleIndRegister = (e) => {
        navigate('/indregister');
    }

    const handleInvestorRegister = (e) => {
        navigate('/investregister');
    }

    const handleTC = (e) => {
        navigate('/term');
    }

    return (
        <>
            <div className="header">
                <button className='btnicon'>
                    <div className="logo2" onClick={handleLandingPage}>
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2"><img src="images/Setting.png" alt="Settings" /></button>
                    <button className="phone2"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>                          
                            <button className="loginleftsidebtn" onClick={handleIndRegister}><img src="images/personalInfo.png" height="25" width="25" alt="Personal Info" /></button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}><img src="images/CompanyRegistrationIcon.png" height="25" width="25" alt="Company Registration" /></button>
                            <button className="loginleftsidebtn" onClick={handleInvestorRegister}><img src="images/InvestorsIcon.png" height="25" width="25" alt="Investors" /></button>
                            <button className="loginleftsidebtn" onClick={handleSrpRegister}><img src="images/SalesPartnerIcon.png" height="25" width="25" alt="Sales Partner" /></button>
                            <button className="loginleftsidebtn"><img src="images/Login Icon .png" height="25" width="25" alt="Login" /></button>
                            <button className="loginleftsidebtn"><img src="images/Login Icon .png" height="25" width="25" alt="Login" /></button>
                        </div>

                        <div className="loginrightside">
                            <div className="loginformtitle">Company Registration</div>
                            <div className="editinfo">
                                <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                            </div>
                            <div className="formdiv">
                                <input type="text" className="inputbg4" placeholder="First Name" required />
                                <input type="text" className="inputbg4" placeholder="Middle Name" required />
                                <input type="text" className="inputbg5" placeholder="Last Name" required />
                                <input type="text" className="inputbg4" placeholder="Cell No" required />
                                <input type="text" className="inputbg4" placeholder="Passport/ID No" required />
                                <input type="text" className="inputbg5" placeholder="Nationality" required />
                                <input type="text" className="inputbg4" placeholder="Gender" required />
                                <input type="text" className="inputbg4" placeholder="Profession" required />
                                <select className="select1">
                                    <option>Registration Purpose</option>
                                    <option style={{color:'black'}}>Option 1</option>
                                    <option style={{color:'black'}}>Option 2</option>
                                </select>
                                <input type="text" className="inputbg6" placeholder="Company Business Name" required />
                                <select className="select2">
                                    <option>Business Type</option>
                                    <option style={{color:'black'}}>Option 1</option>
                                    <option style={{color:'black'}}>Option 2</option>
                                </select>
                                <input type="text" className="inputbg7" placeholder="Trade Lenience Number" required />
                                <input type="text" className="inputbg7" placeholder="Email Address" required />
                                <input type="text" className="inputbg8" placeholder="Floor No" required />
                                <input type="text" className="inputbg8" placeholder="Flat No" required />
                                <input type="text" className="inputbg8" placeholder="Street" required />
                                <input type="text" className="inputbg7" placeholder="Email Address" required />
                                <select className="inputbg8">
                                    <option>Country of Reg</option>
                                    <option style={{color:'black'}}>Option 2</option>
                                </select>
                                <select className="inputbg8">
                                    <option>State</option>
                                    <option style={{color:'black'}}>Option 2</option>
                                </select>
                                <input type="text" className="inputbg8" placeholder="Postal Code" required />
                                <div className="fileleft">
                                    <input type="file" className="file1" placeholder="Trade Copy" required />
                                    Click Here
                                </div>
                                <div className="fileleft2">
                                    <input type="file" className="file2" placeholder="Passport Copy" required />
                                    Click Here
                                </div>
                                <div className="fileleft2">
                                    <input type="file" className="file3" placeholder="Request Letter" required />
                                    Click Here
                                </div>
                                <div className="clicknext">
                                    <button onClick={handleTC}>Click Next to Proceed* <img src="images/ClickNextToProced.png" alt="Click Next" /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="Tiktok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CRegister;
