import React from "react";
import { useNavigate } from "react-router-dom";

const IndRegister = () => {
    const navigate = useNavigate();

    const handlelandingpage = (e) => {
        navigate('/')
    }

    const handleCRegister = (e) => {
        navigate('/cregister')
    }

    const handlesrpregister = (e) => {
        navigate('/srpregister')
    }
    const handleIndregister = (e) => {
        navigate('/indregister')
    }
    const handleInvestorregistor = (e) => {
        navigate('/investregister')
    }
    const handlemessage = (e) => {
        navigate('/message')
    }

    const handleemployeinfo = (e) =>{
        navigate('/employeinfo')
    }

    return (
        <>
            <div className="header">
                <button className='btnicon'>
                    <div className="logo2" onClick={handlelandingpage}>
                        <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings Icon" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone Icon" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">

                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">

                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndregister}>
                                <img src="images/personalInfo.png" alt="Personal Info Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" alt="Company Registration Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorregistor}>
                                <img src="images/InvestorsIcon.png" alt="Investors Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handlesrpregister}>
                                <img src="images/SalesPartnerIcon.png" alt="Sales Partner Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleemployeinfo}>
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                        </div>

                        <div className="loginrightside">
                            <div className="loginformtitle">Individual Client Registration</div>
                            <div className="editinfo">
                                <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info Icon" /></button>
                            </div>
                            <div className="formdiv">

                                <input type="text" className="inputbg4" placeholder="First Name" required="required" />
                                <input type="text" className="inputbg4" placeholder="Middle Name" required="required" />
                                <input type="text" className="inputbg5" placeholder="Last Name" required="required" />
                                <input type="text" className="inputbg4" placeholder="Cell No" required="required" />
                                <input type="text" className="inputbg4" placeholder="Passport/ID No" required="required" />
                                <input type="text" className="inputbg5" placeholder="Nationality" required="required" />
                                <input type="text" className="inputbg4" placeholder="Gender" required="required" />
                                <input type="text" className="inputbg4" placeholder="Profession" required="required" />
                                <select className="select1">
                                    <option>Registration Purpose</option>
                                    <option style={{ color: 'black' }}>Option 1</option>
                                </select>

                                <input type="text" className="inputbg7" placeholder="Email Address" required="required" />
                                <input type="text" className="inputbg8" placeholder="Floor No" required="required" />
                                <input type="text" className="inputbg8" placeholder="Flat No" required="required" />
                                <input type="text" className="inputbg8" placeholder="Street" required="required" />
                                <input type="text" className="inputbg7" placeholder="Residential Address*" required="required" />
                                <select className="inputbg8">
                                    <option>Stay Country</option>
                                    <option style={{ color: 'black' }}>Option 2</option>
                                </select>
                                <select className="inputbg8">
                                    <option>State</option>
                                    <option style={{ color: 'black' }}>Option 2</option>
                                </select>

                                <input type="text" className="inputbg8" placeholder="Postal Code" required="required" />

                                <div className="indbox">
                                    <div className="companytermheading">Terms & Conditions Agreement*</div>
                                    <div className="companytext2">
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>

                                    <div className="termagree">
                                        <input type="checkbox" /> I Agree with Terms & Conditions*
                                    </div>
                                    <div>
                                        <button className="sub1" onClick={handlemessage}>Submit</button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support Icon" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline Icon" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint Icon" /></button>
                        <button><img src="images/Update.png" alt="Update Icon" /></button>
                        <button><img src="images/Tiktok.png" alt="TikTok Icon" /></button>
                        <button><img src="images/FB.png" alt="Facebook Icon" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube Icon" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp Icon" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter Icon" /></button>
                        <button><img src="images/Insta.png" alt="Instagram Icon" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn Icon" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest Icon" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus Icon" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit Icon" /></button>
                    </div>

                </div>
            </div>

        </>
    )
};

export default IndRegister;
