import React from "react";
import { useNavigate } from "react-router-dom";

const VehicalCompartment = () => {
    const navigate = useNavigate();

    const handleMainpage = (e) => {
        navigate('/');
    }

    return (
        <>
            <div className="header">
                <button className="btnicon">
                    <div className="logo2" onClick={handleMainpage}>
                        <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className='maindiv'>
                    <div className="searchbar3"  >
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input
                                type="text"
                                placeholder="Search here Business Windows"
                                className="searchfield"
                            />
                            <input type="button" className="voicebtn" />
                        </form>

                        <div className="vcglassdiv">
                        
                            
                            <div className="leftsidebar">
                                <button><img src="images/LeftIcon1.png" alt="Icon1" /></button>
                                <button><img src="images/LeftIcon2.png" alt="Icon2" /></button>
                                <button><img src="images/LeftIcon1.png" alt="Icon1" /></button>
                                <button><img src="images/LeftIcon2.png" alt="Icon2" /></button>
                                <button><img src="images/LeftIcon1.png" alt="Icon1" /></button>
                                <button><img src="images/LeftIcon2.png" alt="Icon2" /></button>
                            </div>

                            <div className="rightcontent">
                                <div className="upperform">
                                    <div className="form-field">

                                        <input type="text" placeholder="Employee ID" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Center Location" />
                                    </div>
                                    <div className="form-field">

                                        <input type="datetime-local" placeholder="DateTime" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="First Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Middle Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="datetime-local" placeholder="Last Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Purpose of Code" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Creation Purpose" />
                                    </div>
                                    <div className="form-field" >
                                        <select>Vehicle Type
                                        <option>Country Name</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Approved by Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Approved By ID" />
                                    </div>
                                    <div className="form-field">

                                        <input type="datetime-local" placeholder="DateTime" />
                                    </div>
                                    {/* Add remaining fields following the same pattern */}
                                </div>
                                <div className="lowerform">
                                    <div className="form-field">

                                        <input type="text" placeholder="Company Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="comapny Code" />
                                    </div>
                                    <div className="form-field">

                                        <input type="datetime-local" placeholder="DateTime" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Package Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Package Code No" />
                                    </div>
                                    

                                    <div className="form-field" >
                                        <select>
                                        <option>Vehicle Name</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    
                                    
                                    <div className="form-field">

                                        <input type="text" placeholder="Model No" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Chassis No" />
                                    </div><div className="form-field" >
                                        <select>
                                        <option>Vehicle Type</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    <div className="form-field" >
                                        <select>
                                        <option>Drive Hand</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    
                                    <div className="form-field">

                                        <input type="text" placeholder="Approved By Name" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Approved by ID" />
                                    </div>
                                    
                                    <div className="form-field">

                                        <input type="datetime-local" placeholder="DateTime" ></input>
                                       
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Purpose of Code" />
                                    </div>
                                    
                                    <div className="form-field" >
                                        <select>
                                        <option>Creation Reason</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Allocated Country" />
                                    </div>
                                    <div className="form-field">

                                        <input type="text" placeholder="Valid Till" />
                                    </div>
                                    <div className="form-field" >
                                        <select>
                                        <option>Valid Till</option>
                                        <option>Option 3</option>
                                        </select>
                                    </div>
                                    <div className="form-field" style={{ width: '100%' }}>
                                        <input type="text" placeholder="Location" />
                                    </div>
                                    
                                </div>

                            </div>

                        </div>
                        <div className="icondiv4">
                            <button>
                                <img src="images/Support.png" alt="Support" />
                            </button>
                            <button>
                                <img src="images/Helpline.png" alt="Helpline" />
                            </button>
                            <button>
                                <img src="images/Complaint.png" alt="Complaint" />
                            </button>
                            <button>
                                <img src="images/Update.png" alt="Update" />
                            </button>
                            <button>
                                <img src="images/Tiktok.png" alt="Tiktok" />
                            </button>
                            <button>
                                <img src="images/FB.png" alt="Facebook" />
                            </button>
                            <button>
                                <img src="images/Youtube.png" alt="Youtube" />
                            </button>
                            <button>
                                <img src="images/Whatsapp.png" alt="Whatsapp" />
                            </button>
                            <button>
                                <img src="images/Twitter.png" alt="Twitter" />
                            </button>
                            <button>
                                <img src="images/Insta.png" alt="Instagram" />
                            </button>
                            <button>
                                <img src="images/Linkedin.png" alt="LinkedIn" />
                            </button>
                            <button>
                                <img src="images/Pinterest.png" alt="Pinterest" />
                            </button>
                            <button>
                                <img src="images/Google Plus.png" alt="Google Plus" />
                            </button>
                            <button>
                                <img src="images/Reddit.png" alt="Reddit" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VehicalCompartment;
