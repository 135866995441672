import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signin = () => {
    const navigate = useNavigate();
   
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    

    const handleMainpage = (e) =>{
        navigate('/')
    }
    const handleresetpassword = (e) => {
        navigate('/rpassword')
    }
    const handleregister = (e) => {
        navigate('/indregister')
    }

    const handleSignIn = async () => {
        navigate('/cp')
        try {
            
          const response = await axios.post('https://8330-39-33-186-130.ngrok-free.app/api/auth/login', {

            email: email,
            password: password,
          });

          if (response.data.access_token) {
            // If 2FA is required
            if (response.data.access_token) {
              alert('welcome to dashboard');
              
            }
          } else {
            // Handle login failure (e.g., incorrect credentials)
            alert('Login failed. Please check your credentials.');
            
          }
        } catch (error) {
          // Handle errors, such as network issues
          console.error('There was an error logging in:', error);
          alert('An error occurred during sign-in. Please try again.');

        }
        // setOpenAuthDialog(true);
      };
    
   

    return (
        <>
            <div className="header">
                <button className="btnbusiness">
                <div className="logo" onClick={handleMainpage}><img src="images/BusinessWindow.png" alt="Logo" /></div></button>
                <div className="topicons">
                    <button className="setting"><img src="images/Setting.png" alt="Settings" /></button>
                    <button className="phone"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="logindiv">
                        <div className="loginicon"><img src="images/Login Icon .png" alt="Login Icon" /></div>
                        <form>
                            <input
                                required
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                 placeholder="Identification Number"
                                className="inputloginbg1"
                                
                            />
                            <input
                                required
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                className="inputloginbg2"
                                placeholder="Password"
                            />
                              <div className="rembtn"><input type="checkbox" className="checkma" /> Remember Me</div>
                            <div className="signbtn">
                                <button  onClick={handleSignIn}>Sign In</button>
                            </div>
                            <button className="btnicon"><div className="resetpassword" onClick={handleresetpassword}><img src="images/ResetPassword.png" alt="Reset Password" /> Reset Password?</div></button>
                            <button className="btnicon newregister" ><div onClick={handleregister}><img src="images/newregister.png" alt="New Register" /> New Register</div></button>
                            
                        </form>
                      
                    </div>

                    <div className="helpdiv">
                        {/* Help Icons */}
                        <div className="helpicons"><img src="images/Support.png" height="18" width="18" alt="Support" /><p>Support</p></div>
                        <div className="helpicons"><img src="images/Helpline.png" height="18" width="18" alt="Help Line" /><p>Help Line</p></div>
                        <div className="helpicons"><img src="images/Complaint.png" height="18" width="18" alt="Complaint" /><p>Complaint</p></div>
                        <div className="helpicons"><img src="images/Update.png" height="18" width="18" alt="Update" /><p>Update</p></div>
                    </div>

                    <div className="icondiv" style={{ marginTop: '20px' }}>
                        {/* Social Media Icons */}
                        <button><img src="images/Tiktok.png" alt="TikTok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signin;
