import React from "react";
import { useNavigate } from "react-router-dom";



const ResetPassword = () => {
    const navigate = useNavigate();

    const handleSignIn = (e) => {
        navigate('/signin')
    }

    const handlereset = (e) => {
        navigate('/reset')
    }
    const handleregister = (e) => {
        navigate('/indregister')
    }

    const handleMainpage = (e) => {
        navigate('/')
    }

    return (
        <>
            <div className="header">
                <button className="btnicon"><div className="logo" onClick={handleMainpage}><img src="images/BusinessWindow.png" alt="Bussines img" /></div></button>
                <div className="topicons">
                    <button className="setting"><img src="images/Setting.png" alt="setting"/></button>
                    <button className="phone"><img src="images/Phone.png" alt="phone img"/></button>
                </div>
            </div>


            <div className="width1">
                <div className="maindiv">



                    <div className="searchbar">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>


                    <div className="logindiv">
                        <div className="resticon"><img src="images/restpasswordtop.png" alt="resetpassword img" /></div>
                        <div className="resttext">Reset Password?<br />
                            Please Enter Below Required to Reset</div>
                        <form>
                            <input type="text" className="inputloginbg1" placeholder="Identification Number" />
                            <input type="text" className="inputloginbg3" placeholder="Enter Email Address" />
                            <input type="password" className="inputloginbg2" placeholder="Enter New Password" />
                            <input type="password" className="inputloginbg2" placeholder="Re-Enter Password" />
                            <div className="rembtn1"><input type="checkbox" className="checkma" /> Remember Me</div>
                            <div className="signbtn1" onClick={handlereset}><img src="images/Create Icon.png" alt="creat img"/><button>Create</button></div>
                            <button className="resetpassword1 btnicon "><div className="" onClick={handleSignIn}><img src="images/Login Icon .png" alt="login.js" height="16" width="16" /> Click to Login</div></button>
                            <button className="btnicon newregister1" onClick={handleregister}><div className=""><img src="images/newregister.png" alt="register Img" /> New Register</div></button>

                        </form>
                    </div>


                    <div className="helpdiv">
                        <div className="helpicons">
                            <img src="images/Support.png" alt="Support Icon" height="18" width="18" />
                            <p>Support</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Helpline.png" alt="Helpline Icon" height="18" width="18" />
                            <p>Help Line</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Complaint.png" alt="Complaint Icon" height="18" width="18" />
                            <p>Complaint</p>
                        </div>
                        <div className="helpicons">
                            <img src="images/Update.png" alt="Update Icon" height="18" width="18" />
                            <p>Update</p>
                        </div>
                    </div>

                    <div className="icondiv" style={{ marginTop: '20px' }}>
                        <button><img src="images/Tiktok.png" alt="TikTok Icon" /></button>
                        <button><img src="images/FB.png" alt="Facebook Icon" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube Icon" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp Icon" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter Icon" /></button>
                        <button><img src="images/Insta.png" alt="Instagram Icon" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn Icon" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest Icon" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus Icon" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit Icon" /></button>
                    </div>




                </div>
            </div>

        </>
    )
}

export default ResetPassword