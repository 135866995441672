import React from "react";
import { useNavigate } from "react-router-dom";

const InvestorRegister = () => {
    const navigate = useNavigate();

    const handlelandingpage = (e) => {
        navigate('/')
    }

    const handleCRegister = (e) => {
        navigate('/cregister')
    }

    const handlesrpregister = (e) =>{
        navigate('/srpregister')
    }
    const handleIndregister = (e) => {
        navigate ('/indregister')
    }
    const handleInvestorregistor = (e) =>{
        navigate('/investregister')
    }
    const handleTC = (e) =>
    {
        navigate('/term')
    }


    return (
        <>
            <div className="header">
            <button className='btnicon'>
                    <div class="logo2" onClick={handlelandingpage}><img src="images/BusinessWindow.png" alt="Bussines imag" /></div>
                </button>
                <div className="topicons">
                    <button className="setting2"><img src="images/Setting.png"  alt="setting img"/></button>
                    <button className="phone2"><img src="images/Phone.png" alt="phone img"/></button>
                </div>
            </div>


            <div className="width1">
                <div className="maindiv">



                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>


                    <div className="glassdiv2">

                    <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndregister}>
                                <img src="images/personalInfo.png" alt="Personal Info Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" alt="Company Registration Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorregistor}>
                                <img src="images/InvestorsIcon.png" alt="Investors Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handlesrpregister}>
                                <img src="images/SalesPartnerIcon.png" alt="Sales Partner Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                        </div>


                        <div className="loginrightside">
                            <div className="loginformtitle">Investors Registration</div>
                            <div className="editinfo"><button>Edit Info <img src="images/EditInfoIcon.png" alt="edid img" /></button></div>
                            <div className="formdiv">

                                <input type="text" className="inputbg4" placeholder="First Name" required="required" />
                                <input type="text" className="inputbg4" placeholder="Middle Name" required="required" />
                                <input type="text" className="inputbg5" placeholder="Last Name" required="required" />
                                <input type="text" className="inputbg4" placeholder="Cell N0" required="required" />
                                <input type="text" className="inputbg4" placeholder="Passport/ID No" required="required" />
                                <input type="text" className="inputbg5" placeholder="Nationality" required="required" />
                                <input type="text" className="inputbg4" placeholder="Gender" required="required" />
                                <input type="text" className="inputbg4" placeholder="Profession" required="required" />
                                <select className="select1">
                                    <option>Registration  Purpose</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                </select>
                                <input type="text" className="inputbg6" placeholder="Company Business Name" required="required" />
                                <select className="select2">
                                    <option>Type of Invesment</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                </select>
                                <select className="inputbg7">
                                    <option>Aproximate Amount</option>
                                    <option style={{color:'black'}} >Option 1</option>
                                    <option style={{color:'black'}} >Option 2</option>
                                </select>
                                <select className="select2">
                                    <option>Area of Invesment</option>
                                    <option style={{color:'black'}} >Option 1</option>
                                    <option style={{color:'black'}} >Option 2</option>
                                </select>
                                <input type="date" name="birthday" className="inputbg7"></input>

                                    <input type="text" className="inputbg7" placeholder="Office Address" required="required" />
                                    <select className='inputbg8' placeholder="County of Res">
                                        <option style={{ color: 'black' }}>County of Res</option>
                                        <option style={{ color: 'black' }}>Type 1</option>
                                        <option style={{ color: 'black' }}>Type 2</option>
                                    </select>
                                    <select className="inputbg8">
                                        <option style={{color:'black'}}  >State</option>
                                        <option style={{color:'black'}} >Option 2</option>
                                    </select>

                                    <input type="text" className="inputbg8" placeholder="Postal Code " required="required" />

                                    <div className="clicknext2" onClick={handleTC}><button>Click Next to Procced* <img src="images/ClickNextToProced.png"  alt="Next Img"/></button></div>

                            </div>
                        </div>

                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support Icon" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline Icon" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint Icon" /></button>
                        <button><img src="images/Update.png" alt="Update Icon" /></button>
                        <button><img src="images/Tiktok.png" alt="TikTok Icon" /></button>
                        <button><img src="images/FB.png" alt="Facebook Icon" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube Icon" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp Icon" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter Icon" /></button>
                        <button><img src="images/Insta.png" alt="Instagram Icon" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn Icon" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest Icon" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus Icon" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit Icon" /></button>
                    </div>





                </div>
            </div>


        </>
    )
}

export default InvestorRegister